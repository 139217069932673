import { NotificationService } from "./../notifications/notifications.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FirebaseMessagesService } from "../firebaseMessages/firebase-messages.service";

declare var $;

interface NotiticationData {
  id: string;
  title: string;
  msg: string;
  body: string;
}

@Injectable({
  providedIn: "root",
})
export class DisplayNotificationsService {
  // Notification Properties
  public unreadNotifications;
  public unreadNotificationsSubject = new BehaviorSubject(0);
  public allowSeeMoreSub = new BehaviorSubject(true);
  public currentNotificationPage: number = 1;
  public lastNotificationPage: number = 2;
  private notifications = [];
  public notificationsSubject = new BehaviorSubject(this.notifications);
  public seeMore: boolean = true;

  constructor(
    private notificationService: NotificationService,
    private messagingService: FirebaseMessagesService
  ) {
    this.messagingService.currentMessage.subscribe((msg) => {
      // if (msg) {
      // Notifications on start
      // this.showNotification("bottom", "left", msg.notification);
      // }
      this.currentNotificationPage = 1;
      this.getAllNotifications();
      this.getTotalUnread();
    });
  }

  // Notification Methods
  private getTotalUnread() {
    this.notificationService.totalUnread().subscribe((res) => {
      this.unreadNotifications = res.data.count;
      this.unreadNotificationsSubject.next(this.unreadNotifications);
    });
  }

  private getAllNotifications() {
    this.notificationService
      .getAllNotificationsPaginate(this.currentNotificationPage)
      .subscribe((res) => {
        if (this.currentNotificationPage > 1) {
          this.notifications.push(...res.data.notifications.data);
        } else {
          this.notifications = res.data.notifications.data;
        }
        this.currentNotificationPage = res.data.notifications.current_page;
        this.lastNotificationPage = res.data.notifications.total_pages;
        this.notificationsSubject.next(this.notifications);
        this.checkForMore();
      });
  }

  public loadMoreNotifications() {
    if (this.currentNotificationPage < this.lastNotificationPage) {
      this.currentNotificationPage++;
      this.getAllNotifications();
    }
  }

  private checkForMore() {
    if (this.currentNotificationPage === this.lastNotificationPage) {
      this.seeMore = false;
      this.allowSeeMoreSub.next(false);
    }
  }

  public markAllNotificationsAsRead() {
    if (this.unreadNotifications > 0) {
      this.notificationService.markAllAsRead().subscribe(() => {
        this.unreadNotifications = 0;
        this.unreadNotificationsSubject.next(this.unreadNotifications);
      });
    }
  }

  public markNotificationAsRead(notificationId: string) {
    let isRead = this.notifications.find(
      (notif) => notif.id === notificationId
    ).read_at;
    if (!isRead) {
      this.notificationService.markAsRead(notificationId).subscribe((res) => {
        this.unreadNotificationsSubject.next(res.data.count);
        // Update notification to be marked as read
        let updateIndex = this.notifications
          .map((item) => item.id)
          .indexOf(notificationId);
        this.notifications[updateIndex].read_at = 1;
      });
    }
  }

  showNotification(from = "bottom", align = "left", data: NotiticationData) {
    // const type = ["default", "info", "success", "warning", "danger"];
    // const color = Math.floor(Math.random() * 4 + 1);
    // this.notifier.show({
    //   type: 'default',
    //   message: data.body,
    // });
    // this.notifier.show({
    //   type: 'success',
    //   message: data.body ,
    // });
    // $.notify(
    //   {
    //     icon: "notifications",
    //     message: data.body,
    //   },
    //   {
    //     type: type[color],
    //     timer: 4000,
    //     placement: {
    //       from: from,
    //       align: align,
    //     },
    //     template:
    //       '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon" role="alert" >' +
    //       '<i class="far fa-bell p-2"></i> ' +
    //       '<span data-notify="title">{1}</span> ' +
    //       '<span data-notify="message">{2}</span>' +
    //       '<div class="progress" data-notify="progressbar">' +
    //       '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 100%;"></div>' +
    //       "</div>" +
    //       '<a routerLink="/order/order-list/details/"' +
    //       'target="{4}" data-notify="url"></a>' +
    //       "</div>",
    //   }
    // );
  }
}
