<div class="LanguageArea" [ngClass]="{ visible: showLang }">
  <div class="LanguageBox">
    <h3>{{ "Select Your Language" | translate }}</h3>
    <ul>
      <li
        role="button"
        *ngFor="let lang of listLang"
        (click)="currentLang = lang"
      >
        <span [ngClass]="{ selectedLang: lang?.lang == currentLang?.lang }">{{
          lang?.text
        }}</span>
      </li>
    </ul>
    <figure>
      <img src="assets/images/Key.png" />
    </figure>
    <button type="button" (click)="setLanguage(currentLang)">
      {{ "Confirm" | translate }}
    </button>
  </div>
</div>
