import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { User } from "../../models/auth.models";
import { HttpClientService } from "../http/http.service";
import { CachingService } from "../caching/caching.service";
import { FirebaseMessagesService } from "../firebaseMessages/firebase-messages.service";
import { take } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class AuthenticationService {
  public redirectUrl: string;
  public asCompany: number;
  public expiredTokenSubject: Subject<boolean>;

  constructor(
    private http: HttpClientService,
    private router: Router,
    private cachingService: CachingService,
    private messagingService: FirebaseMessagesService
  ) {
    this.expiredTokenSubject = new Subject();
  }

  public set User(user) {
    user.firebase_token = this.messagingService.firebaseToken
      ? this.messagingService.firebaseToken
      : "Not Allowed";
    user.send_push = 1;
    this.cachingService.set("user", user);
  }

  public get User(): User {
    return this.cachingService.get("user") as User;
  }

  public get Token() {
    if (this.User) {
      return this.User.token;
    }
  }

  public get isLoggedIn() {
    if (!this.User) {
      return false;
    }

    return true;
  }

  public logout() {
    this.http
      .post({}, "auth/logout")
      .pipe(take(1))
      .subscribe(
        (_) => this.removeUser(),
        (_) => this.removeUser()
      );
  }

  removeUser() {
    this.cachingService.remove("user");
    this.router.navigate(["/landing"]);
  }

  // login methods

  AdminLogin(body) {
    return this.http.post(body, "users/login");
  }

  LoginWithPhone(body) {
    return this.http.post(body, "auth/phone-login");
  }
  LoginWithMail(body) {
    return this.http.post(body, "auth/login");
  }
  otpConfirmation(body) {
    return this.http.post(body, "auth/verifications/confirm-phone-login");
  }

  // registeration

  registerSendOtp(body) {
    return this.http.post(body, "auth/verifications/register-send-code");
  }

  registerOtpConfirmation(body) {
    return this.http.post(body, "auth/verifications/register-confirm-code");
  }

  register(body) {
    return this.http.post(body, "auth/register");
  }

  overwriteHeaderCountryCode(code) {
    this.http.countryCode = code;
    this.http.updateHeaderBasedOnCountry();
  }

  // password reset

  resetPassSendOtp(body) {
    return this.http.post(body, "auth/forget-password");
  }

  resetPassword(body) {
    return this.http.post(body, "auth/reset-password");
  }
}
