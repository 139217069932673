import { Component, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { RegionsService } from "./core/services/regions/regions.service";
import { take } from "rxjs/operators";
import { CachingService } from "./core/services/caching/caching.service";

export let browserRefresh = false;
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private regionsService: RegionsService,
    private cachingService: CachingService
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }
  ngOnInit() {
    // get all small services and store its in ls
    this.getAllCountries();
  }

  getAllCountries() {
    if (this.regionsService.allCountries.length == 0) {
      this.regionsService
        .getAllCountries()
        .pipe(take(1))
        .subscribe((res) => {
          this.cachingService.set("allCountries", res.data);
          this.regionsService.allCountries = res.data;
        });
    }
  }
}
