import {
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { AuthenticationService } from "../services/auth/auth.service";
import { LoaderService } from "../services/loader/loader.service";
import { CachingService } from "../services/caching/caching.service";

@Injectable()
export class Interceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];
  constructor(
    private _auth: AuthenticationService,
    private router: Router,
    private loaderService: LoaderService,
    private cachingService: CachingService
  ) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let request = req.clone({});

    this.requests.push(req);
    this.loaderService.isLoading.next(true);

    // if token exits will be added to request headers

    if (this._auth.Token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this._auth.Token}`,
          "device-push-token": `${this._auth.User.firebase_token}`,
          "send-push": `${this._auth.User.send_push}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 200) {
              this.removeRequest(req);
            } else if (event.status === 403) {
              this._auth.expiredTokenSubject.next(true);
              this.cachingService.remove("user");
              this.router.navigate(["/landing"]);
              this.removeRequest(req);
            }
          }
        },
        (err: any) => {
          this.removeRequest(req);

          Swal.fire("خطأ ", `${err.error.message}`, "error");

          if (err instanceof HttpErrorResponse) {
            if (err.error.message === "Unauthenticated") {
              this._auth.expiredTokenSubject.next(true);
              this.cachingService.remove("user");

              this.router.navigate(["/landing"]);
            }
            if (err.status == 403) {
              this._auth.expiredTokenSubject.next(true);
              this.cachingService.remove("user");

              this.router.navigate(["/landing"]);
              this.removeRequest(req);
              Swal.fire("خطأ ", `${err.error.message}`, "error");
            }
          }
          this.removeRequest(req);

          return of(err);
        }
      )
    );
  }
}
