import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: "Home",
    link: "/home",
    icon: "bx bxs-dashboard text-danger",
  },
  {
    id: 2,
    label: "Items Management",
    link: "/properties",
    icon: "bx bx-buildings text-info",
  },

  {
    id: 3,
    label: "Coupons Management",
    link: "/coupons",
    icon: "bx bxs-offer text-success",
  },
  {
    id: 4,
    label: "Booking Management",
    link: "/booking-managment",
    icon: "bx bx-calendar-check",
    badge: {
      variant: "success reservations-counter-id",
      text: "0",
    },
  },
  {
    id: 5,
    label: "Messages",
    link: "/messages",
    icon: "bx bx-chat text-primary",
    badge: {
      variant: "danger message-counter-id",
      text: "0",
    },
  },
  {
    id: 6,
    label: "Profits Management",
    link: "/profits",
    icon: "bx bxs-dollar-circle  text-success",
  },
  {
    id: 7,
    label: "Reviews Management",
    link: "/reviews-managment",
    icon: "bx bxs-star text-warning",
  },
  {
    id: 8,
    label: "Profile",
    link: "/profile",
    icon: "bx bxs-user text-info",
  },
  {
    id: 9,
    label: "Bank Accounts",
    link: "/bank-accounts",
    icon: "bx bxs-bank text-dark",
  },
  {
    id: 10,
    label: "Settings",
    link: "/settings",
    icon: "bx bx-cog bx-spin",
  },
  {
    id: 11,
    label: "Affiliate Program",
    link: "/affiliate-program",
    icon: "bx bx-group text-info",
  },
];
