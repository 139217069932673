import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./core/guards/auth.guard";
import { LayoutComponent } from "./layouts/layout.component";
import { Page404Component } from "./extrapages/page404/page404.component";
import { ComingsoonComponent } from "./extrapages/comingsoon/comingsoon.component";

const routes: Routes = [
  {
    path: "landing",
    loadChildren: () =>
      import("./landing/landing.module").then((m) => m.LandingModule),
  },

  // load of components that need to rendered in layout
  {
    path: "",
    component: LayoutComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
    canActivate: [AuthGuard],
  },

  // load of properties componnets taht need to rendered out of layout
  {
    path: "property",
    loadChildren: () =>
      import("./properties-actions/properties-actions.module").then(
        (m) => m.PropertiesActionsModule
      ),
    canActivate: [AuthGuard],
  },

  // pages that no need to be authenticated
  {
    path: "others",
    loadChildren: () =>
      import("./extrapages/extrapages.module").then((m) => m.ExtrapagesModule),
  },
  { path: "**", redirectTo: "landing" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "top",
      // relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
